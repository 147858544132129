import { useRef } from "react";
import '../unwanted/startup.css'
import start from '../imgs/didit/TV logo.jpg';
import post from '../imgs/didit/first.jpg';



const Startup = () => {

    const firstVideoRef = useRef(null);
    const secondVideoRef = useRef(null);

    
    return (
        <div className='startup-singam'>

           

                <div className='start-cont'>

                    <div className='content'>
                        <div className='typewriter'>
                            <h3>STARTUP SINGAM</h3>
                        </div>
                        <div className="firstpara">
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Startup Singam is Tamil Nadu's first startup reality show, designed to empower entrepreneurs and promote startup investments. The 13-episode series features three startups per episode, pitching their ideas to investors and mentors for feedback, guidance, and potential funding. Premiering on January 26, 2025, it airs every Sunday from 11:30 AM to 12:30 PM on Vijay TV and streams on Disney+ Hotstar. Embodying its tagline கனவை எட்ட, கதவை தட்டு (Kanavai Etta, Kadhavai Thattu) - "Where dreams meet reality." - it encourages entrepreneurs to push boundaries and seize opportunities, turning their visions into successful ventures.</p>
                        </div>
                        <div className='dev'>
                            <p> Suresh Iyer - Producer  <br />
                                Mohanakrishnan S N - Director   <br />
                                Athish Vinayagam Paramasivam - Executive Producer </p> </div>


                        <div className='team'>

                            <div className='first'>

                                <div><span className='bold'>
                                    Creative Director </span><br /><p>
                                        Aswin Rao  <br />
                                        Abbhinav Kastura </p>
                                </div>


                                <div>
                                    <span className='bold'>
                                        Co Director </span> <br /><p>
                                        Chithra Jayakumar</p>
                                </div>


                                <div>
                                    <span className='bold'>
                                        Script writer </span> <br /><p>
                                        Francis <br />
                                         Clement Welson </p>
                                </div>



                                <div>
                                    <span className='bold'>
                                        Assistant director</span> <br /><p>
                                        Parvadhavardhini Sivakumar <br />
                                        Udhayaprabhu Jayakumar <br />
                                        Ramya Tamilselvam <br />
                                        Hari Prasath  K  </p>
                                </div>
                            </div>

                            <div className='second'>

                                <div>
                                    <span className='bold'>
                                        AV Director</span> <br /><p>
                                        Jones kathai </p>
                                </div>

                                <div>
                                    <span className='bold'>
                                        Post Production Head </span> <br /><p>
                                        Rajesh Narayanan</p>
                                </div>
                                <div>
                                    <span className='bold'>
                                        Production Controller </span> <br /><p>
                                        Paramesh</p>
                                </div>

                            </div>
                        </div>
                    </div>
                
            </div>
            

            <div className='herader-sec'>
            <img alt='startup singam , lme, lotus meta entertainment ' src={start} />
                <img alt='' src={post} />
                
            </div>
        </div>
    )
}

export default Startup