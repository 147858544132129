import React from 'react'
import '../css/about.scss'
import { Slider } from './Slider';
import { Glassmorphi } from './Glassmorphi';
import { Aboutus } from './Aboutus';
import { Contactus } from './Contactus';
import { Footer } from './Footer';
import { Services } from './Services';
import { AboutSuresh } from './AboutSuresh';
import { Evangelist } from './Evangelist';

export const Home1 = () => {

   
        const totalTriangles = 200;
      
        // Helper function to generate random values
        const random = (max) => Math.floor(Math.random() * max);
        // Generate triangle divs
        const triangles = Array.from({ length: totalTriangles }, (_, index) => {
          const size = `${random(50)}px`;
          const rotate = `${random(360)}deg`;
          const x = `${random(1000)}px`;
          const y = `${random(1000)}px`;
          const delay = `${index * -(10 / totalTriangles)}s`;
          const animationName = `anim${index + 1}`;
  return (
    <div>
       <div
        key={index}
        className="tri"
        style={{
          borderTop: `${size} solid hsla(${random(360)}, 100%, 50%, 1)`,
          borderRight: `${size} solid transparent`,
          borderLeft: `${size} solid transparent`,
          marginLeft: `calc(${size} / -2)`,
          marginTop: `calc(${size} / -2)`,
          transform: `rotate(${rotate}) translate3d(0, 0, -1500px) scale(0)`,
          animation: `${animationName} 10s infinite linear`,
          animationDelay: delay,
        }}
      ></div>
    </div>
     );
    });
  
    return (
      <div>
      <div className="main-container">
      <div className="wrap">
        {triangles}
        {/* Add a new div for centered text */}
        <div className="centered-text">
          <p className='head-text'>LOTUS META </p>
          <p className='head-small'>ENTERTAINMENT</p>
        </div>
      </div>
    </div>
    <Glassmorphi />
   
    <AboutSuresh />
    <Evangelist />
    
    
    </div>
    )
  };
  