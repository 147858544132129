import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Footer = () => {
  return (
    <div>
        
        <footer>
            
      <p>&copy; 2025 Lotus Meta Entertainmet - All Rights Reserved.</p>
     
    </footer>
    </div>
  )
}
